import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import PersonDetail from "./Components/PersonDetail/PersonDetail";
import CondolenceFlowers from "./Components/Flowers/CondolenceFlowers";
import Header from "./Components/Header/Header";
import PersonList from "./Components/PersonList/PersonList";
import {PAGES} from "./Pages";
import FuneralFlower from "./Components/OrderDetail/FuneralFlower";
import {ParticipationCardInfo} from "./Components/ParticipationCard/ParticipationCardInfo";
import {ParticipationCardShop} from "./Components/ParticipationCard/Shop/ParticipationCardShop";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PersonListByFuneralHome from "./Components/PersonList/PersonListByFuneralHome";
import {CondolencePage} from "./Components/Condolences/CondolencePage";

function App() {
    return (
        <React.Fragment>
            <ToastContainer/>
            <Router>
                <Header/>
                <Routes>
                    <Route path={PAGES.CondolenceForm} Component={CondolencePage}/>
                    <Route path={PAGES.CondolenceFlowers} Component={CondolenceFlowers}/>
                    <Route path={PAGES.FuneralFlower} Component={FuneralFlower}/>
                    <Route path={PAGES.ParticipationCard} Component={ParticipationCardInfo}/>
                    <Route path={PAGES.ParticipationCardShop} Component={ParticipationCardShop}/>
                    <Route path={PAGES.PersonDetail} Component={PersonDetail}/>
                    <Route path={PAGES.PersonList} Component={PersonList}/>
                    <Route path={PAGES.PersonListByFuneralHome} Component={PersonListByFuneralHome}/>
                </Routes>
                <Footer/>
            </Router>
        </React.Fragment>
    );
}

export default App;
