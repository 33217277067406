import React, {Fragment, useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link, useParams} from "react-router-dom";
import '../../App.scss';
import {useServiceGridItems} from "../RandomGrid/useServiceGridItems";
import {Farewell} from "./Farewell";
import {FuneralHome} from "./FuneralHome";
import {CondolenceApi} from "../../api/CondelenceApi";
import {MourningVisits} from "./MourningVisits";
import {Show} from "../Util/Show";
import {Spinner} from "react-bootstrap";
import {Trans, useTranslation} from "react-i18next";
import PersonInfo from "./PersonInfo";
import "./PersonInfo.scss"
import {BackButton} from "../BackButton/BackButton";
import {defaultInvitationModalData, InvitationModal} from "./InvitationModal";
import {toast} from "react-toastify";
import Button from "react-bootstrap/Button";
import {PublicCondolences} from "./PublicCondolences";
import CondolenceCard from "../CondolenceCard/CondolenceCard";


function PersonDetail() {
    const [dossierData, setDossierData] = useState();
    const [loading, setLoading] = useState(false);
    const gridItems = useServiceGridItems(dossierData);
    const {name} = useParams();
    const {t} = useTranslation();
    const [registrationModalData, setRegistrationModalData] = useState(defaultInvitationModalData);

    useEffect(() => {
        setLoading(true);
        fetchDossierByAlias(name).then((res) => {
            setDossierData(res);
            setLoading(false);
        })
            .catch(_ => toast.error(t('error.general'),{position: "top-center"}));
    }, [name, t]);

    useEffect(() => {
        if (!!gridItems.coffee_table) {
            console.log()
            gridItems.coffee_table.onClickHandler = () => {
                console.log("coffee")
                setRegistrationModalData({
                    show: true,
                    closeHandler: () => setRegistrationModalData(defaultInvitationModalData),
                    title: t('common.register_coffee_table'),
                    submitHandler: (name, email, phone, address, amount_of_people, participants, confirmation) => {
                        CondolenceApi.registerCoffeeTable(dossierData.dossier_number, name, email, phone, address, amount_of_people, participants, confirmation)
                            .then(_ => {
                                toast.success(t('common.register_coffee_table_success'));
                                setRegistrationModalData(defaultInvitationModalData)
                            })
                            .catch(_ => {
                                toast.error(t('error.general'),{position: "top-center"});
                            });
                    }
                });
            }
        }
        if (!!gridItems.funeral_invitations) {
            gridItems.funeral_invitations.onClickHandler = () => {
                setRegistrationModalData({
                    show: true,
                    closeHandler: () => setRegistrationModalData(defaultInvitationModalData),
                    title: t('common.register_funeral'),
                    submitHandler: (name, email, phone, address, amount_of_people, participants, confirmation) => {
                        CondolenceApi.registerFuneral(dossierData.dossier_number, name, email, phone, address, amount_of_people, participants, confirmation)
                            .then(_ => {
                                toast.success(t('common.register_funeral_success'));
                                setRegistrationModalData(defaultInvitationModalData)
                            })
                            .catch(_ => {
                                toast.error(t('error.general'),{position: "top-center"});
                            });
                    }
                });
            }
        }
    }, [dossierData, gridItems, t])

    async function fetchDossierByAlias(alias = String) {
        return CondolenceApi.getDossierByAlias(alias);
    }

    function getFuneralExecutorName() {
        if (!!dossierData.funeral_entrepreneur) {
            return dossierData.funeral_entrepreneur;
        }
    }

    function renderLiveStreamButton() {
        if (dossierData?.show_livestream === true) {
            return (
                <Link to={dossierData.livestream_page} target={"_blank"}>
                    <Button className={"livestream-button"}>
                        <Trans t={t} i18nKey={"common.livestream"}/>
                    </Button>
                </Link>
            );
        }
        return (<Fragment/>);
    }

    return (
        <Container>
            <Row>
                <BackButton path={"/"} textComponent={<Trans t={t} i18nKey={"common.back"}/>}/>
            </Row>
            <Row>
                {dossierData?.show_snoeck_button === true &&
                    <BackButton path={"https://www.snoeck-izegem.be/rouwberichten.html"}
                                textComponent={<Trans t={t} i18nKey={"common.back_snoeck"}/>}/>}
            </Row>
            {loading === true && (
                <Row className={"justify-content-center mb-4 mb-4"}>
                    <Spinner animation="border"/>
                </Row>
            )}
            {!!dossierData &&
                <Fragment>
                    <Row className="mt-5 align-middle text-center text-md-start">
                        <PersonInfo dossierData={dossierData}/>
                        <Col md={3} className={"person-detail-info-col ms-4"}>
                            <Farewell className={"mb-5"} funeralData={dossierData.funeral}
                                      funeralLocationData={dossierData.funerallocation}/>
                            <Show>
                                <Show.When
                                    isTrue={dossierData.funeral.PUBLICCHARACTER === '1' || dossierData.funeral.PUBLICCHARACTER === true}>
                                    <MourningVisits className="mb-5" showFuneral={dossierData.show_funeral}
                                                    mourningVisit={dossierData.mourningvisit}
                                                    mourningVisitLocation={dossierData.mourningvisitlocation}/>
                                </Show.When>
                            </Show>
                            <PublicCondolences className="mb-5"
                                               condolences={dossierData.condoleances}
                                               canShowPublicCondolences={dossierData.funeral.ALLOWPUBLICCONDOLENCES === "1"}/>

                            <FuneralHome funeralHomeData={dossierData.funeralhome}
                                         funeralEntrepreneur={getFuneralExecutorName()}/>

                            {renderLiveStreamButton()}
                        </Col>

                    </Row>
                    {/*<CondolenceGrid gridElements={gridItems} dossierNumber={dossierData.dossier_number}*/}
                    {/*                isCondolencePublic={dossierData.funeral.ALLOWPUBLICCONDOLENCES === "1"}/>*/}

                    <CondolenceCard  gridElements={gridItems}
                                     dossierNumber={dossierData.dossier_number}
                                     isCondolencePublic={dossierData.funeral.ALLOWPUBLICCONDOLENCES === "1"}
                    />
                </Fragment>}
            <InvitationModal
                show={registrationModalData.show}
                title={registrationModalData.title}
                closeModal={registrationModalData.closeHandler}
                externalSubmitHandler={registrationModalData.submitHandler}
            />
        </Container>
    )
}

export default PersonDetail;
