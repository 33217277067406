import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowRight} from "@fortawesome/free-solid-svg-icons/faCircleArrowRight";
import {Link} from "react-router-dom";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons/faCircleXmark";
import classnames from "classnames";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


function CondolenceCard({gridElements, dossierNumber, isCondolencePublic}) {
    const {t} = useTranslation();

    const elements = gridElements.elementsExceptCondolence;
    if (elements.length === 0) {
        return <Fragment/>
    }

    function getLinkTarget(data) {
        if (data.urlIsExternal === true) {
            return ({target: "_blank"});
        }
        return {};
    }

    function getCard(element, index) {
        let card = (
            <Card className={classnames("my-2", "border-0", {
                'bg-opacity-10': !element.active,
                'bg-opacity-25': element.active
            }, element.bg ? element.bg : 'bg-light')}>
                <Card.Title className={classnames("m-2", "p-2", {'text-black-50': !element.active})}>{element.title}
                    <FontAwesomeIcon className="float-end"
                                     icon={element.active ? faCircleArrowRight : faCircleXmark}
                    />
                </Card.Title>
                <Card.Body>
                    {t("person_detail_card_text.condolence")}
                </Card.Body>
            </Card>);

        if (!!element.url && element.active) {
            card = (<Link className={"anchor-no-decoration"} to={element.url} {...getLinkTarget(element)}>
                {card}
            </Link>);
        }

        return (<Fragment>
            <Col lg={4} key={index}>
                {card}
            </Col>
        </Fragment>);
    }


    return (
        <div>
            <Row className="m-2">
                {gridElements.elementsExceptCondolence.map((element, i) => getCard(element, i))}
            </Row>
        </div>
    )
}

export default CondolenceCard
