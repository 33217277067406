import {useMemo} from "react";
import {useParams, generatePath} from "react-router-dom";
import {PAGES} from "../../Pages";
import {useTranslation} from "react-i18next";

export const GRID_KEYS = {
    CONDOLENCE: "CONDOLENCE",
    FLOWERS: "FLOWERS",
    MOURN_COMMUNICATION: "MOURN_COMMUNICATION",
    MOURNING_CARD: "MOURNING_CARD",
    CONSOLATION_MEAL: "CONSOLATION_MEAL",
    COFFEE_TABLE: "COFFEE_TABLE",
    FUNERAL_INVITATION: "FUNERAL_INVITATION",
    REMINDER_CARD: "REMINDER_CARD"
}

// const COFFEE_TABLE_URLS = process.env.REACT_APP_COFFEE_TABLE_URLS.split(',');
// const FUNERAL_INVITATION_URLS = process.env.REACT_APP_FUNERAL_INVITATION_URLS.split(',');

export const useServiceGridItems = (element) => {
    const {name} = useParams();
    const {t} = useTranslation();

    // function isValidUrl(urls) {
    //     // check if the list of urls contains the current hostname
    //     return urls.some(url => window.location.hostname.includes(url));
    // }

    return useMemo(() => {
        let service = {elementsExceptCondolence: [], elementCount: 0};
        if (!!element) {
            service.condolence = {
                title: t('common.condolences'),
                subText: t("person_detail_card_text.condolence"),
                url: generatePath(PAGES.CondolenceForm, {name}),
                key: GRID_KEYS,
                active: element.show_condoleances === "1",
                bg: 'bg-light'
            };
            service.elementsExceptCondolence.push(service.condolence);
            service.elementCount += 1;

            service.flower = {
                title: t('common.condolences_flower'),
                subText: t("person_detail_card_text.flower"),
                url: generatePath(PAGES.CondolenceFlowers, {name}),
                key: GRID_KEYS.FLOWERS,
                active: showFlowers(element),
                bg: 'bg-black'
            };
            service.elementsExceptCondolence.push(service.flower);
            service.elementCount += 1;

            service.mourning_communication = {
                title: t('common.mourning_communication'),
                subText: t("person_detail_card_text.flower"),
                url: element.pdf,
                urlIsExternal: true,
                key: GRID_KEYS.MOURN_COMMUNICATION,
                active: element.has_pdf,
                bg: 'bg-info',
            };
            service.elementsExceptCondolence.push(service.mourning_communication);
            service.elementCount += 1;

            service.hngry = {
                title: t('common.consolation_meals'),
                subText: t("person_detail_card_text.consolation_meal"),
                url: element.consolation_meals_url,
                urlIsExternal: true,
                key: GRID_KEYS.CONSOLATION_MEAL,
                active: element.show_consolation_meals,
                bg: 'bg-success-subtle'
            };
            service.elementsExceptCondolence.push(service.hngry);
            service.elementCount += 1;

            service.mourning_card = {
                title: t('common.consolation_card'),
                subText: t('person_detail_card_text.mourning_card'),
                url: generatePath(PAGES.ParticipationCard, {name}),
                urlIsExternal: false,
                key: GRID_KEYS.MOURNING_CARD,
                active: element.show_mourning_card,
                bg: 'bg-danger'
            };
            service.elementsExceptCondolence.push(service.mourning_card);
            service.elementCount += 1;

            service.reminder = {
                title: t('common.reminder_communication'),
                subText: t('person_detail_card_text.reminder_communication'),
                url: element.reminder_pdf,
                urlIsExternal: true,
                key: GRID_KEYS.REMINDER_CARD,
                active: element.show_reminder_pdf,
                bg: 'bg-warning'
            };
            service.elementsExceptCondolence.push(service.reminder);
            service.elementCount += 1;
            // TODO COFFEE show_funeral_invitations
            // if (element.show_coffeetable && isValidUrl(COFFEE_TABLE_URLS)) {
            //     service.coffee_table = {title: t('common.register_coffee_table'), key: GRID_KEYS.COFFEE_TABLE}
            //     service.elementsExceptCondolence.push(service.coffee_table);
            //     service.elementCount += 1;
            // }
            // if (element.show_funeral_invitations && isValidUrl(FUNERAL_INVITATION_URLS)) {
            //     service.funeral_invitations = {title: t('common.register_funeral'), key: GRID_KEYS.COFFEE_TABLE}
            //     service.elementsExceptCondolence.push(service.funeral_invitations);
            //     service.elementCount += 1;
            // }
        }
        return service;
    }, [element, name, t])
}

export function showFlowers(element) {
    return element.show_flowers === true
}
